import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Rich text rendering
import { ContentContainer } from "../components/Theme"
import MarkdownView from "react-showdown"

const ThoughtsPageContentfulTemplate = ({ data, location }) => {
  const post = data.contentfulThoughts
  const siteTitle = data.site.siteMetadata.title

  const Title = styled.h1`
    font-weight: normal;
  `

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={post.title} description={post.subtitle} />
      <ContentContainer>
        <header>
          <Title data-sal="fade" data-sal-easing="ease">
            {post.title}
          </Title>
        </header>
        <section>
          <MarkdownView
            markdown={post.content.content}
            options={{ tables: true, emoji: true, simpleLineBreaks: true }}
          />
        </section>
      </ContentContainer>
    </Layout>
  )
}

export default ThoughtsPageContentfulTemplate

export const pageQuery = graphql`
  query ContentfulThoughtsPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }

    contentfulThoughts(slug: { eq: $slug }) {
      title
      subtitle
      content {
        content
      }
    }
  }
`
